import React, { useState } from "react"
import { find } from "lodash"
import styles from "./styles.css"
import Card from "mill/components/Card"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import CursorPagination from "shared/components/CursorPagination"
import useCursorPagination from "shared/hooks/useCursorPagination"

import FETCH_QUESTION_DRILLDOWN_DATA from "mill/graphql/FetchQuestionDrilldownData"

const RetiredPill = styled.span`
  background: ${props => props.theme.colors.secondary};
  border-radius: 3px;
  font-size: 1rem;
  color: white;
  padding: 0.3rem 1rem;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 1.5rem;
  letter-spacing: 1px;
  position: relative;
  top: -2px;
`

const QuestionDrilldown = props => {
  const [selectedQuestionIds, setSelectedQuestionIds] = useState([])
  const [sortDirection, setSortDirection] = useState("asc")
  const queryParams = {
    beforeParam: `${props.category}Before`,
    afterParam: `${props.category}After`
  }
  const cursorPagination = useCursorPagination(queryParams)
  const [values, setValues] = useState({
    searchTerm: "",
    sortBy: "questions.question asc"
  })

  const { loading, error, data } = useQuery(FETCH_QUESTION_DRILLDOWN_DATA, {
    variables: {
      campaignId: props.campaignID,
      clusterId: props.clusterId,
      clusterType: props.clusterType,
      categories: [props.category],
      searchTerm: values.searchTerm,
      sortBy: values.sortBy,
      ...cursorPagination
    }
  })
  if (error) return <p>Error :(</p>

  const questions =
    data && data.campaignQuestions ? data.campaignQuestions.edges : []

  const handleQuestionOrder = e => {
    e.preventDefault()
    const oppositeOrder = sortDirection === "asc" ? "desc" : "asc"
    setSortDirection(oppositeOrder)
    setValues({
      ...values,
      sortBy: `questions.question ${oppositeOrder}`
    })
  }

  const handleFilterChange = event => {
    setValues({ ...values, searchTerm: event.target.value })
  }

  const renderToggle = questionID => {
    const symbol = selectedQuestionIds.includes(questionID) ? "-" : "+"
    return <a onClick={() => toggleClick(questionID)}>{symbol}</a>
  }

  const toggleClick = questionID => {
    if (selectedQuestionIds.includes(questionID)) {
      setSelectedQuestionIds(selectedQuestionIds.filter(id => id != questionID))
    } else {
      setSelectedQuestionIds([...selectedQuestionIds, questionID])
    }
  }

  const renderStandardRow = question => {
    const {
      id,
      retired,
      topicList,
      firstAttemptPerformance,
      secondAttemptPerformance
    } = question

    return (
      <tr className={styles.baseRow} onClick={() => toggleClick(id)}>
        <td
          className={selectedQuestionIds.includes(id) ? styles.active : null}>
          {renderToggle(id)}
        </td>

        <td>
          <span className={styles.questionTitle}>
            {question.question}
            {retired && <RetiredPill>Retired</RetiredPill>}
          </span>
        </td>
        <td>{topicList}</td>
        <td>{firstAttemptPerformance ? `${firstAttemptPerformance}%` : "-"}</td>
        <td>
          {secondAttemptPerformance ? `${secondAttemptPerformance}%` : "-"}
        </td>
      </tr>
    )
  }

  const renderCollapsableRow = question => {
    let mappedAnswers = []
    question.answers.map(answer => {
      const answerDistribution = find(question.answerDistribution, {
        answerId: answer.id
      })
      mappedAnswers.push({
        id: answer.id,
        answer: answer.answer,
        isCorrect: answer.isCorrect,
        distributionPercentage: answerDistribution.distributionPercentage
      })
    })

    if (selectedQuestionIds.includes(question.id)) {
      if (question.answers) {
        return (
          <tr className={styles.shownRow}>
            <td />
            <td colSpan="1">
              <table>
                <thead>
                  <tr>
                    <th>Answers</th>
                  </tr>
                </thead>
                <tbody>
                  {mappedAnswers.map(answer => {
                    return (
                      <tr key={`a-${answer.id}`}>
                        <td
                          className={
                            answer.isCorrect ? styles.correctAnswer : null
                          }>
                          {answer.answer}
                          {answer.isCorrect && <i className="fa fa-check" />}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </td>
            <td colSpan="3">
              <table>
                <thead>
                  <tr>
                    <th>% Selected</th>
                  </tr>
                </thead>
                <tbody>
                  {mappedAnswers.map(answer => {
                    return (
                      <tr key={`aa-${answer.id}`}>
                        <td
                          className={
                            answer.isCorrect ? styles.correctAnswer : null
                          }>
                          {answer.distributionPercentage || "0"}%
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </td>
          </tr>
        )
      }
      return (
        <tr className={styles.shownRow}>
          <td />
          <td colSpan="4">Loading...</td>
        </tr>
      )
    }
    return null
  }

  const renderRow = question => {
    return (
      <tbody key={question.id}>
        {renderStandardRow(question)}
        {renderCollapsableRow(question)}
      </tbody>
    )
  }

  const sortArrowClass =
    sortDirection === "asc" ? "fa fa-chevron-up" : "fa fa-chevron-down"

  return (
    <Card>
      <h3 className={styles.heading}>{props.title}</h3>
      <input
        type="text"
        className={styles.searchBox}
        placeholder="Search..."
        value={values.searchTerm}
        onChange={handleFilterChange}
      />
      <table className={styles.table} style={{ marginBottom: "3rem" }}>
        <thead>
          <tr>
            <th />
            <th
              onClick={e => handleQuestionOrder(e)}
              className={styles.sortable}
              width="48%">
              Question
              <span className={styles.arrow}>
                <i className={sortArrowClass} />
              </span>
            </th>
            <th>Topics</th>
            <th>Correct 1st try</th>
            <th>Correct 2nd try</th>
          </tr>
        </thead>
        {questions.map(question => renderRow(question.node))}
      </table>

      {!loading && data && (
        <CursorPagination
          {...data.campaignQuestions.pageInfo}
          queryParams={queryParams}
        />
      )}
    </Card>
  )
}

export default QuestionDrilldown
